import React from "react";

const MainHeader = () => {
  return (
    <header className="main__header">
      <div className="container main__header-container">


        <div className="main__header-right">
            <h1 className='main_text' data-aos="fade-right" data-aos-delay="100"  data-aos-duration="1000">Enjoy the Invaluable Rewards of Growing Old</h1>
            <p data-aos="fade-right" data-aos-delay="400"  data-aos-duration="1000">Every family has a unique story. Understanding that story is the key to helping you find the right living arrangement for your loved one. We Sri Yoga Nandi , have always connected to the families.</p>
            <a href="/#getIn"> <button   className='btn lg' data-aos="fade-right" data-aos-delay="100"  data-aos-duration="1000"> Get Started</button></a>
        </div>
      </div>
    </header>

  )
}

export default MainHeader;
